<template>
	<div>
		<div v-if="languageSelector" class="max-w-screen-mobile mx-auto h-screen  py-2 text-center realtive">
            <Header :language="lang" noPin/>
            <div class="text-center mx-6 h-4/5 flex flex-col justify-around">
							<div>
                <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('language.selectTitle') }}</h4>
                <Language flag mobile :language="lang" @langchanged="langchanged_" class="my-4" v-on:click="selectorChange" />
                <p class="font-body text-bodyL text-sepiaBlack dark:text-white px-6">{{ $t('language.text') }}</p>
							</div>
            		<!-- <Button :text="$t('language.saveBtn')" buttonType="primary" fullWidth v-on:click="selectorChange"/> -->
            </div>
    	</div>
		<div v-else class="max-w-screen-mobile mx-auto h-screen py-2 relative">
			<Header :language="lang" 
				@langchanged="langchanged_"
				noPin>
			</Header>
			<div v-if="btnDisabel">
				<Loader />
			</div>
			<div class="mx-6 text-center">
				<h4 class="font-heading text-h4 text-sepiaBlack dark:text-white pt-14">{{ $t('login.login') }}</h4>
				<div class="hidden mx-auto" id="loginError">
					<SystemMessage :text="$t('login.bademailorpass')" type='error' />
				</div>
				<div class="hidden mx-auto" id="forgotError">
					<SystemMessage :text="$t('login.bademail')" type='error' />
				</div>
				<Input :placeholder="$t('login.email')" type="email" name="email" id="email" required />
				<Input :placeholder="$t('login.password')" type="password" id="password" name="password"/>
				<p class="font-body text-bodyS text-twine text-left">{{ $t('login.passwordHelpText') }}</p>
				<button v-on:click="forgotPassword" :disabled="btnDisabel" xl class="underline text-center min-w-max inline-block bg-transparent rounded-full text-redDamask font-body text-bodyL font-medium cursor-pointer" >
					{{ $t('login.newpass') }}
				</button>
				<Button :disabledBtn="btnDisabel" xl :text="$t('login.login')" buttonType="primary" fullWidth v-on:click="login" />
				<Button href="/quick-login" :disabledBtn="btnDisabel" xl :text="$t('login.register')" buttonType="secondary" fullWidth />
			</div>
			<Modal @btnDisabelChange="btnDisabelChange" class="hidden" />
		</div>
	</div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import Modal from '@/components/mobile/ForgotPasswordModal.vue';
import Language from '@/components/Language.vue';
import SystemMessage from '@/components/SystemMessage.vue';
import Loader from '@/components/Loader.vue';

export default {
	name: "Login",
    components: {
        Header,
        Button,
        Input,
        Modal,
		Language,
		SystemMessage,
		Loader
    },
	data(){
		return {
			username: '',
			password: '',
			btnDisabel: false,
			baseURL: process.env.VUE_APP_AXIOS_URL,
			languageSelector: true,
			lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
		}
	},
	mounted() {
		this.$i18n.locale = this.lang;
		this.logedinCheck();
    },
	methods: {
		login() {
			try{
                document.querySelector("#systemM").classList.remove("hidden");
				document.querySelector("#loginError").classList.add("hidden");
				document.querySelector("#forgotError").classList.add("hidden");
				this.btnDisabel = true;
				this.email = document.querySelector('#email').value;
				this.password = document.querySelector('#password').value;
				const loginResult = this.$store.dispatch('login', { username: this.email, password: this.password});
				loginResult.then((res) => {
					if (res.error) {
						document.querySelector("#loginError").classList.remove("hidden");
					} else {
						this.$router.push('/thank-you');
					}
					this.btnDisabel = false;
				}).catch((e) => {
					this.btnDisabel = false;
					// console.log(e);
					document.querySelector("#loginError").classList.remove("hidden");
				});
			}catch(e){
				// console.log(e);
				document.querySelector("#loginError").classList.remove("hidden");
				this.btnDisabel = false;
			}
		},
		forgotPassword() {
			try{
                document.querySelector("#systemM").classList.remove("hidden");
				document.querySelector("#loginError").classList.add("hidden");
				document.querySelector("#forgotError").classList.add("hidden");
				this.btnDisabel = true;
				this.email = document.querySelector('#email').value;
				const post_variabels = {email: this.email, url: window.location.protocol + '//' + window.location.hostname + '/password-reset'};
				this.axios.post(this.baseURL + `/wp-json/wp/v2/forgot-password`, post_variabels, {})
            	.then(response => {
					const response_data = response.data;
					if (response_data.error) {
						this.btnDisabel = false;
						document.querySelector("#forgotError").classList.remove("hidden");
					} else {
            			document.querySelector("#popupModal").classList.remove("hidden");
									this.btnDisabel = false;
					}
				}).catch( (error) => {
					// console.log(error);
					this.btnDisabel = false;
				});

			}catch(e){
				// console.log(e);
				document.querySelector("#forgotError").classList.remove("hidden");
				this.btnDisabel = false;
			}
		},
		langchanged_(value) {
			this.lang = value;
		},
		btnDisabelChange() {
			this.btnDisabel = false;
		},
		selectorChange() {
            this.languageSelector = false;
        },
		logedinCheck() {
			const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  			if (authUser) {
        		this.$router.push('/mobile/menu');
			}
		}
	},
}
</script>